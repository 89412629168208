// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-downloadassets-js": () => import("./../../../src/pages/downloadassets.js" /* webpackChunkName: "component---src-pages-downloadassets-js" */),
  "component---src-pages-example-components-js": () => import("./../../../src/pages/example-components.js" /* webpackChunkName: "component---src-pages-example-components-js" */),
  "component---src-pages-faqsandcontacts-js": () => import("./../../../src/pages/faqsandcontacts.js" /* webpackChunkName: "component---src-pages-faqsandcontacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knorrbrandvision-js": () => import("./../../../src/pages/knorrbrandvision.js" /* webpackChunkName: "component---src-pages-knorrbrandvision-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-onpackclaims-js": () => import("./../../../src/pages/onpackclaims.js" /* webpackChunkName: "component---src-pages-onpackclaims-js" */),
  "component---src-pages-ourbrandequities-js": () => import("./../../../src/pages/ourbrandequities.js" /* webpackChunkName: "component---src-pages-ourbrandequities-js" */),
  "component---src-pages-ourcolourpalette-js": () => import("./../../../src/pages/ourcolourpalette.js" /* webpackChunkName: "component---src-pages-ourcolourpalette-js" */),
  "component---src-pages-ourproductpillars-js": () => import("./../../../src/pages/ourproductpillars.js" /* webpackChunkName: "component---src-pages-ourproductpillars-js" */),
  "component---src-pages-packaginginpractise-js": () => import("./../../../src/pages/packaginginpractise.js" /* webpackChunkName: "component---src-pages-packaginginpractise-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-theknorrlogo-js": () => import("./../../../src/pages/theknorrlogo.js" /* webpackChunkName: "component---src-pages-theknorrlogo-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */)
}

