/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/global.css"
import "./src/css/custom.css"
import "./src/css/pages.css"



// manage the intersectional observer absence in IE11 & safari?
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === "undefined") {
        console.log('Loading Intersectional Observer')
      await import("intersection-observer")
    }
  }